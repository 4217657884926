import useSWR from 'swr';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { type I18nSettings } from '../../types';

async function load() {
  const resp = await apiService.userSettings.getMyI18nSettings();
  return resp.data.i18n ?? null;
}

async function save(settings: Partial<I18nSettings>) {
  const resp = await apiService.userSettings.updateMyI18nSettings(settings);
  return resp.data.i18n ?? null;
}

export function useMyI18nSettings() {
  const { data, error, isValidating, mutate } = useSWR(
    '/my/settings/i18n',
    load,
    { shouldRetryOnError: false }
  );

  const update = useLiveCallback(async (...args: Parameters<typeof save>) => {
    const locale = await save(...args);
    mutate(locale);
    return locale;
  });

  return {
    i18nSettings: data,
    isLoading: isValidating,
    error,
    update,
  };
}

export function useMyI18nSettingsGetter() {
  const i18n = useMyI18nSettings();
  return useLiveCallback(() => i18n);
}
