import { match } from 'ts-pattern';

import { EnumsSlideLayout } from '@lp-lib/api-service-client/public';
import {
  type Block,
  type BlockKnife,
  type BlockSummary,
  BlockType,
  BlockTypeV2,
  type DrawToWinBlock,
  type FillInTheBlanksBlock,
  type HiddenPictureBlock,
  type JeopardyBlock,
  type MemoryMatchBlock,
  type MultipleChoiceBlock,
  type QuestionBlock,
  type ResultsBlock,
  type RoleplayBlock,
  type ScenarioBlock,
  type SlideBlock,
  type SparkifactBlock,
  type SwipeToWinBlock,
} from '@lp-lib/game';

export class QuestionBlockKnife implements BlockKnife<QuestionBlock> {
  summary(block: QuestionBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Question',
      title: block.fields.internalLabel || block.fields.question,
      subtitle: `Answer: ${block.fields.answer}`,
      coverMedia: block.fields.questionMedia || block.fields.answerMedia,
    };
  }
}

export class MultipleChoiceBlockKnife
  implements BlockKnife<MultipleChoiceBlock>
{
  summary(block: MultipleChoiceBlock): BlockSummary {
    let subtitle = 'No Answer';
    for (const choice of block.fields.answerChoices) {
      if (choice.correct) {
        subtitle = `Answer: ${choice.text}`;
        break;
      }
    }
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Multiple Choice',
      title: block.fields.internalLabel || block.fields.question,
      subtitle,
      coverMedia: block.fields.questionMedia || block.fields.answerMedia,
    };
  }
}

export class MemoryMatchBlockKnife implements BlockKnife<MemoryMatchBlock> {
  summary(block: MemoryMatchBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Memory Match',
      title: block.fields.internalLabel || block.fields.text,
      subtitle: `${block.fields.numberOfCardPairs * 2} cards`,
      coverMedia: block.fields.backgroundMedia,
    };
  }
}

export class HiddenPictureBlockKnife implements BlockKnife<HiddenPictureBlock> {
  summary(block: HiddenPictureBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Hidden Picture',
      title: block.fields.internalLabel || block.fields.pictures?.[0]?.question,
      subtitle: `${block.fields.pictures?.length ?? 0} pictures`,
      coverMedia:
        block.fields.backgroundMedia || block.fields.pictures?.[0]?.mainMedia,
    };
  }
}

export class JeopardyBlockKnife implements BlockKnife<JeopardyBlock> {
  summary(block: JeopardyBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Jeopardy',
      title: block.fields.internalLabel || 'Jeopardy',
    };
  }
}

export class SlideBlockKnife implements BlockKnife<SlideBlock> {
  summary(block: SlideBlock): BlockSummary {
    const prettyTypeName = match(block.fields.layout)
      .with(EnumsSlideLayout.SlideLayoutBigMedia, () => 'Video Slide')
      .with(EnumsSlideLayout.SlideLayoutFreeForm, () => 'Document Slide')
      .with(EnumsSlideLayout.SlideLayoutSlideshow, () => 'Slideshow Slide')
      .otherwise(() => 'Info Slide');
    return {
      id: block.id,
      type: block.type,
      prettyTypeName,
      title: block.fields.internalLabel || block.fields.heading,
    };
  }
}

export class DrawToWinBlockKnife implements BlockKnife<DrawToWinBlock> {
  summary(block: DrawToWinBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Draw to Win',
      title: block.fields.internalLabel || block.fields.question,
    };
  }
}

export class RoleplayBlockKnife implements BlockKnife<RoleplayBlock> {
  summary(block: RoleplayBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Roleplay',
      title: block.fields.internalLabel || block.fields.systemPrompt,
    };
  }
}

export class SparkifactBlockKnife implements BlockKnife<SparkifactBlock> {
  summary(block: SparkifactBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Sparkifact',
      title: block.fields.internalLabel,
    };
  }
}

export class FillInTheBlanksBlockKnife
  implements BlockKnife<FillInTheBlanksBlock>
{
  summary(block: FillInTheBlanksBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Fill in the Blanks',
      title: block.fields.internalLabel,
    };
  }
}

export class SwipeToWinBlockKnife implements BlockKnife<SwipeToWinBlock> {
  summary(block: SwipeToWinBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Swipe to Win',
      title: block.fields.internalLabel || block.fields.instruction,
    };
  }
}

export class ScenarioBlockKnife implements BlockKnife<ScenarioBlock> {
  summary(block: ScenarioBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Scenario',
      title: block.fields.internalLabel,
    };
  }
}

export class ResultsBlockKnife implements BlockKnife<ResultsBlock> {
  summary(block: ResultsBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Results',
      title: block.fields.internalLabel,
    };
  }
}

const BlockKnifeFactory: { [key in BlockTypeV2]: BlockKnife<Block> } = {
  [BlockTypeV2.QUESTION]: new QuestionBlockKnife(),
  [BlockTypeV2.MULTIPLE_CHOICE]: new MultipleChoiceBlockKnife(),
  [BlockTypeV2.MATCH]: new MemoryMatchBlockKnife(),
  [BlockTypeV2.HIDDEN_PICTURE]: new HiddenPictureBlockKnife(),
  [BlockTypeV2.JEOPARDY]: new JeopardyBlockKnife(),
  [BlockTypeV2.SLIDE]: new SlideBlockKnife(),
  [BlockTypeV2.DRAW_TO_WIN]: new DrawToWinBlockKnife(),
  [BlockTypeV2.ROLEPLAY]: new RoleplayBlockKnife(),
  [BlockTypeV2.SPARKIFACT]: new SparkifactBlockKnife(),
  [BlockTypeV2.FILL_IN_THE_BLANKS]: new FillInTheBlanksBlockKnife(),
  [BlockTypeV2.SWIPE_TO_WIN]: new SwipeToWinBlockKnife(),
  [BlockTypeV2.SCENARIO]: new ScenarioBlockKnife(),
  [BlockTypeV2.RESULTS]: new ResultsBlockKnife(),
};

// This is introduced to bypass the circular dependency issue
export class BlockKnifeV2Utils {
  static Summary(block: Block, full = false): BlockSummary {
    const knife = BlockKnifeFactory[block.type as unknown as BlockTypeV2];
    if (!knife) {
      return {
        id: block.id,
        type: block.type,
        prettyTypeName: 'Unknown',
        title: 'Unknown',
      };
    }
    return knife.summary(block, full);
  }
  static GetPersonalityIds(block: Block): string[] {
    switch (block.type) {
      case BlockType.QUESTION:
      case BlockType.MULTIPLE_CHOICE:
      case BlockType.MEMORY_MATCH:
      case BlockType.HIDDEN_PICTURE:
      case BlockType.JEOPARDY:
      case BlockType.DRAW_TO_WIN:
      case BlockType.ROLEPLAY:
      case BlockType.FILL_IN_THE_BLANKS:
      case BlockType.SWIPE_TO_WIN:
      case BlockType.SCENARIO:
      case BlockType.RESULTS:
        return block.fields.personalityId ? [block.fields.personalityId] : [];
      case BlockType.SLIDE:
        return (
          block.fields.dialogue?.entries?.map((e) => e.personalityId) ?? []
        );
      default:
        return [];
    }
  }
}
